const NotFound = () => {
    let meta = document.createElement("meta");
    meta.setAttribute("name", "prerender-status-code");
    meta.setAttribute("content", "404");
    document.getElementsByTagName("head")[0].appendChild(meta);
    return (
        <>
            <div className="content_bg content container">
                <div className="content__hr"/>
                <h1>404</h1>
                <div className="content__hr"/>
            </div>
        </>
    )
}
export default NotFound;