import axios from "axios";
import queryString from "query-string";

const axiosClient = axios.create({
    baseURL: "https://kino-trend.ru/",
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
    },
    paramsSerializer: params => queryString,
});

axiosClient.interceptors.request.use(
    async (config) => config,
    (error) => {
        return Promise.reject(error);
    });

axiosClient.interceptors.response.use((response)=>{
    if(response && response.data) {
        return response.data;
    }

    return response;
}, (error) => {
    return null;
});

export default axiosClient;