import React from 'react';

import Grid from '../../components/grid/Grid'


const KT = () => {
    return (
        <>
            <div className="container">
                <div className="section mb-3">
                    <Grid />
                </div>
            </div>
        </>
    );
}

export default KT;
