import {useEffect, useState} from "react";
import ktApi from "../../api/ktApi";
import Card, { FakeCard } from "../card/Card";
import './grid.scss';

const Grid = () => {
    const [items, setItems] = useState([]);

    const fakeArr = Array.from(Array(12).keys());

    useEffect(()=>{
        const getList = async () => {
            return await ktApi.getList();
        }
        getList().then(r => setItems(r ? r.movies : []));
    }, [setItems])

    return (
        <>
            <div className="card-grid mt-4">
                {
                    items.length > 0 ? (
                        items.map((item) => <Card item={item} key={item.filmID} />)
                    ) : (
                        fakeArr.map((items, i)=> <FakeCard key={i}/>)
                    )
                }
            </div>
        </>
    )
}
export default Grid;