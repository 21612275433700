import {Route, Routes} from 'react-router-dom';

import Home from '../pages/home/Home';
import KT from '../pages/kt/KT';
import Detail from "../pages/detail/Detail";
import Online from "../pages/online/Online";
import NotFound from "../pages/notfound/NotFound";
import Header from "../components/header/Header";

const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<Header />}>
                <Route index element={<Home/>}/>
                <Route path='web' element={<KT/>}/>
                <Route path='web/details/:id' element={<Detail/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
            <Route path='/web/play' element={<Online/>}/>
        </Routes>
    );
}
export default AppRoutes;