import {Player} from "react-tuby";
import "react-tuby/css/main.css";
import ReactHlsPlayer from "react-hls-player";

const MyPlayer = (props) => {
    return(
        <Player src={props.video}>
            {(ref, props) => <ReactHlsPlayer playerRef={ref} {...props} autoPlay />}
        </Player>
    )
}
export default MyPlayer;