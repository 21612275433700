import axiosClient from "./axiosClient";

const ktApi = {
    getAppInfo: ()=>{
        const url = '/version.json';
        return axiosClient.get(url);
    },
    getList: ()=>{
        const url = '/kinotrend/data.json';
        return axiosClient.get(url);
    }
}

export default ktApi;