import React, {useEffect, useState} from "react";
import ktApi from "../../api/ktApi";
import "./Home.scss"
import Button from "../../components/button/button";


const Home = () => {
    const [info, setInfo] = useState(null);

    useEffect(()=>{
        async function fetchData() {
            const resp = await ktApi.getAppInfo()
            setInfo(resp);
        }
        fetchData().then();
    }, [setInfo])

    return info ? (
        <>
            <div className="content_bg content container">
                <div className="content__hr"/>
                <h1>v{info.short_version}</h1>
                <p><a href={info.download_url}><Button>Скачать</Button></a></p>
                <div className="content__hr"/>
                <p><a href="https://t.me/kinotrend_group">Telegram Group</a></p>
                <p><a href="https://t.me/kinotrend_info">Telegram Channel</a></p>
                <p><a href="https://boosty.to/Nemiroff">Donate</a></p>
            </div>
        </>
    ) : (<></>)
}
export default Home;