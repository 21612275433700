import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import MovieInfo from "./Info"

import Api from "../../api/ktApi";

import './detail.scss';

const Detail = () => {
    const {id} = useParams();
    const [item, setItem] = useState(null);

    useEffect(() => {
        const getDetail = async () => {
            window.scrollTo(0, 0);
            return await Api.getList();
        }
        getDetail().then(r => setItem(r.movies.filter(i => i.filmID === parseInt(id)).pop()));
    }, [id]);

    return (
        item && (
            <>
                <div className="banner" style={{backgroundImage: `url(${item.bigPosterURL || item.posterURL})`}}/>
                <div className="container">
                    <div className="section mb-3">
                        <MovieInfo item={item}/>
                    </div>
                </div>
            </>
        )
    )
}
export default Detail;