import React from "react";

import {useNavigate} from "react-router-dom";

const Info = props => {
    const item = props.item;
    const history = useNavigate();
    const premierDate = new Date(item.premierDate)

    return (
        <div className="movie-content">
            <div className="movie-content__poster">
                <div onClick={() => history(-1)} className="movie-content__poster__img" style={{backgroundImage: `url(${item.posterURL})`}}/>
                {
                    item.ratingFloat > 0 && (<div className="movie-content__poster__rating">{item.rating}</div>)
                }
            </div>
            <div className="movie-content__info">
                <h1 className="title">{item.nameRU} ({item.year})</h1>
                {   /* Слоган */
                    item.slogan !== "" && <p><i>{item.slogan}</i></p>
                }

                <div className="bubble">
                    { item.filmLength && <span className="bubble__item">{item.filmLength} мин</span> }
                    { item.ratingAgeLimits !== "" ?
                        <span className="bubble__item">{item.ratingAgeLimits.toUpperCase()}+</span> :
                        item.ratingMPAA !== "" && <span className="bubble__item">{item.ratingMPAA.toUpperCase()}</span>
                    }
                    {
                        item.genre.split(', ').map((g, i) => (<span key={i} className="bubble__item">{g}</span>))
                    }
                </div>
                {
                    item.country !== "N/A" && (
                        <div className="country">
                            <span><strong>Страна:</strong> {item.country}</span>
                        </div>
                    )
                }
                {   /* Режиссер */
                    item.directors !== "" && (
                        <div className="directors">
                            <span><strong>Режиссер:</strong></span> {item.directors}
                        </div>
                    )
                }

                {   /* Актеры */
                    item.actors !== "" && (
                        <div className="casts">
                            <span><strong>В ролях: </strong></span> { item.actors }
                        </div>
                    )
                }

                {
                    <div className="premier">
                        <span><strong>Премьера ({item.premierType}):</strong> {`${premierDate.getDate()}.${premierDate.getMonth()}.${premierDate.getFullYear()}`}</span>
                    </div>
                }

                {   /* Описание */
                    item.description !== "" && (
                        <div className="overview">
                            <h4>Описание:</h4>
                            {item.description}
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default Info