import React from "react";
import {useNavigate} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import './card.scss';

const Card = props => {
    const navigate = useNavigate();
    const item = props.item;
    const link = 'details/' + item.filmID;

    return (
        <div className="card" style={{backgroundImage: `url(${item.posterURL})`}} onClick={()=>navigate(link)}>
            {
                item.ratingFloat > 0 && <div className="card__rating">{item.rating}</div>
            }
            <div className="card__info">{item.nameRU}</div>
        </div>
    )
}

export const FakeCard = () => {
    return (
        <>
            <Skeleton
                baseColor="#fb63104d"
                highlightColor="#fb6310"
                className="card"
                enableAnimation
                inline
            />
        </>
    )
}
export default Card;