import {useSearchParams} from "react-router-dom";
import Player from "../../components/player/MyPlayer";
import {useEffect, useState} from "react";
import ktApi from "../../api/ktApi";

const Online = () => {
    let [params] = useSearchParams();
    const [video, setVideo] = useState(null);

    useEffect(() => {
        const getTrans = async () => {
            return ktApi.getOnline(params.get('b'), [`${params.get('id')}/${params.get('tr')}`]);
        }
        getTrans().then(r => setVideo(r ? r.result : []))
    }, [params])

    return (video && <Player video={video.hls ? video.hls : video.mp4}/>)
}
export default Online