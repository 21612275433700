import React, {useEffect, useRef} from 'react';

import {Link, Outlet, useLocation} from 'react-router-dom';

import './header.scss';

import logo from "../../assets/logo.png";

const headerNav = [
    {
        display: 'Главная',
        path: '/',
        hide: false
    },
    {
        display: 'Web',
        path: '/web',
        hide: false
    }
];

const Header = () => {

    const { pathname } = useLocation();
    const headerRef = useRef(null);

    const active = headerNav.findIndex(e => e.path === pathname);

    useEffect(() => {
        const shrinkHeader = () => {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                headerRef.current.classList.add('shrink');
            } else {
                headerRef.current.classList.remove('shrink');
            }
        }
        window.addEventListener('scroll', shrinkHeader);
        return () => {
            window.removeEventListener('scroll', shrinkHeader);
        };
    }, []);

    return (<>
        <div ref={headerRef} className="header">
            <div className="header__wrap container">
                <div className="logo">
                    <img src={logo} alt="KinoTrend"/>
                    <Link to="/">KinoTrend</Link>
                </div>
                <ul className="header__nav">
                    {
                        headerNav.map((e, i) => (
                            !e.hide && (
                            <li key={i} className={`${i === active ? 'active' : ''}`}>
                                <Link to={e.path}>
                                    {e.display}
                                </Link>
                            </li>
                            )
                        ))
                    }
                </ul>
            </div>
        </div>
        <Outlet /></>
    );
}

export default Header;
